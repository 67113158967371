<template>
  <div>
    <div
      class="popup-feedback-container"
      @click="clickFeedbackLink"
      v-b-tooltip.hover.right
      title="Painel de Sugestões"
    >
      <i class="fa-solid fa-lightbulb"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fider_url: "https://feedback.wellon.digital/"
    };
  },
  methods: {
    async clickFeedbackLink() {
      const FEATURE_FLAG_REDIRECIONAR_USUARIO_AUTENTICADO_FIDER_ID = 1;
      const feature_flag = await this.$getFeatureFlag(
        FEATURE_FLAG_REDIRECIONAR_USUARIO_AUTENTICADO_FIDER_ID
      );
      if (feature_flag && feature_flag.ffl_ativo) {
        const usuario = JSON.parse(localStorage.getItem("usuario"));
        window.open(usuario.usu_fider_url, "_blank");
      } else {
        window.open(this.fider_url, "_blank");
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.popup-feedback-container {
  position: fixed;
  bottom: 90px;
  // left: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  background: #472282;
  z-index: 9999;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  i {
    color: #fff;
    font-size: 1.8rem;
  }
}
</style>
