<template>
  <div class="v-dashboard-container">
    <template v-if="exibirDashboard">
      <Feedback />
      <Atalhos :usuario="usuario" />

      <div class="row" v-if="temBanners">
        <div class="col-12">
          <b-carousel
            id="carousel-1"
            :interval="6000"
            :controls="false"
            :indicators="true"
            background="transparent"
          >
            <b-carousel-slide
              v-for="(banner, idx) in banners"
              :key="'banner-' + idx"
            >
              <template #img>
                <img
                  style="border-radius: 8px !important"
                  :src="banner.rb_url"
                  :alt="banner.rb_titulo"
                  :exibicoes="banner.rb_exibicoes"
                  class="img-fluid mb-5 w-100"
                  @click="bannerClick(banner)"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5"></div>
        <div class="col-8">
          <EstatisticasCampanha
            :dados="cardsCampanha"
            :periodo="filtroPeriodo"
            :carregando="carregandoDadosPeriodo"
            :exibirCampanhasSemCallback="exibirCampanhasSemCallback"
          >
            <div class="d-flex justify-content-start align-items-center">
              <div
                class="d-flex align-items-center mr-2 border rounded"
                style="
                  background-color: #472282 !important;
                  padding: 0.525rem 0.75rem !important;
                  cursor: pointer;
                "
              >
                <label
                  class="mb-0 mr-3 text-white"
                  style="cursor: pointer; font-size: 0.925rem"
                  for="exibirCampanhasSemCallback"
                  >Sem callback
                </label>
                <InputSwitch
                  inputId="exibirCampanhasSemCallback"
                  v-model="exibirCampanhasSemCallback"
                />
              </div>
              <div>
                <a
                  class="btn btn-sm btn-light"
                  @click.stop.prevent="
                    abrirSelecaoPeriodos('estatisticas_campanha')
                  "
                  style="background: #472282; color: #fff; white-space: nowrap"
                >
                  <span class="font-size-base">{{ textoDataInicial }}</span>
                  <span class="font-size-base">{{ textoDataFinal }}</span>
                </a>
                <date-range-picker
                  ref="campanha_picker"
                  opens="left"
                  :always-show-calendars="false"
                  :ranges="dateRangePicker.periodos"
                  :locale-data="dateRangePicker.localizacao"
                  :auto-apply="true"
                  v-model="dateRangePicker.dataInicioFim"
                  @select="periodoSelecionado"
                  class="pt-0"
                ></date-range-picker>
              </div></div
          ></EstatisticasCampanha>
        </div>
        <div class="col-4">
          <RankingElosDuradouros
            :dados="rankingElosDuradouros"
            :carregando="carregandoRankingElosDuradouros"
          />
        </div>
        <div
          class="col-12 col-md-8 d-flex flex-column justify-content-between"
          style="gap: 2rem"
        >
          <div
            class="w-100 h-100"
            v-if="graficoDiario.series_whatsapp && graficoDiario.series_sms"
          >
            <GraficoLinha
              :total_whatsapp="graficoDiario.total_whatsapp"
              :total_sms="graficoDiario.total_sms"
              :series_whatsapp="graficoDiario.series_whatsapp"
              :series_sms="graficoDiario.series_sms"
              :total_aglutinado="graficoDiario.total_aglutinado"
              :total_aglutinado_series="graficoDiario.total_aglutinado_series"
              titulo="Mensagens"
              tipo="data"
              :periodo="filtroPeriodo"
              :carregando="!exibirGraficos"
              ref="graficoLinha"
              ><div class="d-flex justify-content-start align-items-center">
                <a
                  class="btn btn-sm btn-light w-100"
                  @click.stop.prevent="abrirSelecaoPeriodos"
                  style="background: #472282; color: #fff; white-space: nowrap"
                >
                  <span class="font-size-base">{{ textoDataInicial }}</span>
                  <span class="font-size-base">{{ textoDataFinal }}</span>
                </a>
                <date-range-picker
                  ref="picker"
                  opens="left"
                  :always-show-calendars="false"
                  :ranges="dateRangePicker.periodos"
                  :locale-data="dateRangePicker.localizacao"
                  :auto-apply="true"
                  v-model="dateRangePicker.dataInicioFim"
                  @select="periodoSelecionado"
                ></date-range-picker></div
            ></GraficoLinha>
          </div>
          <div class="w-100">
            <EstatisticasCredito :dados="cards" :plano="tipoPlanoConta" />
          </div>
        </div>
        <div
          class="col-12 col-md-4 timeline-container"
          style="max-height: 650px !important"
        >
          <TimelineCampanhas
            :titulo="timeline.titulo"
            :lista="timeline.lista"
            :carregando="!exibirTimeline"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="alert alert-success" role="alert">
        O Dashboard está oculto para otimizar o carregando do sistema no modo
        Super Administrador. Se deseja visualizar o Dashboard
        <a href="javascript:;" class="alert-link" @click="ativarDashboard"
          >clique aqui</a
        >.
      </div>
    </template>
  </div>
</template>

<script>
window.chatwootLaunchForSupport();
window.addEventListener("chatwoot:ready", function () {
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  window.$chatwoot.setLabel("Painel-Interno");
  window.$chatwoot.setUser(usuario.login, {
    name: usuario.nome + " | " + usuario.cliente,
    email: usuario.login,
    phone_number: usuario.celular,
    company_name: usuario.conta,
    identifier_hash: usuario.hash_chatwoot,
    description: usuario.cliente
  });

  try {
    const exapnded_text = document.getElementById(
      "woot-widget--expanded__text"
    );
    if (exapnded_text) exapnded_text.style.display = "none";
    const widget_bubble_icon = document.getElementById(
      "woot-widget-bubble-icon"
    );
    if (widget_bubble_icon) widget_bubble_icon.style.margin = "14px";
    const widget_bubble = document.querySelector(".woot-widget-bubble");
    if (widget_bubble) {
      widget_bubble.style.height = "unset";
      widget_bubble.title = "Fale com o time Wellon";
      widget_bubble.style.backgroundColor = "#472282";
    }
    const woot_bubble = document.querySelector(".woot-widget-bubble");
    if (woot_bubble) woot_bubble.classList.add("toggled-woot");
  } catch (error) {
    console.log(error);
  }
});

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EstatisticasCredito from "./dashboard/EstatisticasCredito.vue";
import Feedback from "./feedback/Feedback.vue";
import DateRangePicker from "vue2-daterange-picker";

import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import EstatisticasCampanha from "./dashboard/EstatisticasCampanha.vue";
import TimelineCampanhas from "./dashboard/TimelineCampanhas.vue";
import GraficoLinha from "./dashboard/GraficoLinha.vue";
import Atalhos from "./dashboard/Atalhos.vue";

import InputSwitch from "primevue/inputswitch";
import RankingElosDuradouros from "./dashboard/RankingElosDuradouros.vue";

export default {
  name: "dashboard",
  components: {
    Feedback,
    EstatisticasCredito,
    EstatisticasCampanha,
    TimelineCampanhas,
    DateRangePicker,
    GraficoLinha,
    Atalhos,
    InputSwitch,
    RankingElosDuradouros
  },
  beforeMount() {
    const exibirDashboard = localStorage.getItem("exibirDashboard");

    if (exibirDashboard === null) {
      localStorage.setItem("exibirDashboard", false);

      if (!this.isAdmin) {
        localStorage.setItem("exibirDashboard", true);
        this.exibirDashboard = true;
      }
    } else {
      this.exibirDashboard = JSON.parse(exibirDashboard);
    }

    const datas = this.obterDatas();

    this.dateRangePicker.dataInicioFim.startDate = datas.ultimos3Dias;
    this.dateRangePicker.dataInicioFim.endDate = datas.hoje;

    this.textoDataInicial = "";
    this.textoDataFinal =
      datas.ultimos3Dias.format("DD/MM/YYYY") +
      " - " +
      datas.hoje.format("DD/MM/YYYY");

    this.dateRangePicker.periodos = {
      Hoje: [datas.hoje.toDate(), datas.hoje.toDate()],
      Ontem: [datas.ontem.toDate(), datas.ontem.toDate()],
      "Últimos 3 dias": [datas.ultimos3Dias.toDate(), datas.hoje.toDate()],
      "Última semana": [datas.ultimaSemana.toDate(), datas.hoje.toDate()],
      "Mês atual": [datas.inicioMesAtual.toDate(), datas.fimMesAtual.toDate()],
      "Mês anterior": [
        datas.inicioMesAnterior.toDate(),
        datas.fimMesAnterior.toDate()
      ],
      "Últimos 30 Dias": [datas.ultimos30Dias.toDate(), datas.hoje.toDate()],
      "Últimos 3 meses": [datas.ultimos3Meses.toDate(), datas.hoje.toDate()],
      "Últimos 6 meses": [datas.ultimos6Meses.toDate(), datas.hoje.toDate()],
      "Últimos 12 meses": [datas.ultimos12Meses.toDate(), datas.hoje.toDate()]
    };

    const usuario = JSON.parse(localStorage.getItem("usuario"));
    this.atalhos = usuario.atalhos;
    this.usuario.atalhos = usuario.atalhos.sort(
      (a, b) => a.upa_ordem - b.upa_ordem
    );
  },
  data() {
    return {
      atalhos: [],
      carregandoDadosPeriodo: false,
      dateRangePicker: {
        dataInicioFim: {
          startDate: null,
          endDate: null
        },
        periodos: {},
        mudouRota: false,
        textoDataInicial: "",
        textoDataFinal: "",
        localizacao: {
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Aplicar",
          cancelLabel: "Cancelar",
          fromLabel: "De",
          toLabel: "Para",
          customRangeLabel: "Personalizado",
          weekLabel: "W",
          daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
          monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
          ],
          firstDay: 1
        }
      },
      conta_id: null,
      banners: [],
      conta: {},
      exibirGraficos: false,
      exibirTimeline: false,
      graficoDiario: {
        total: 0,
        series: [],
        series_whatsapp: null,
        series_sms: null
      },
      timeline: {
        titulo: "Timeline",
        lista: []
      },
      cards: {
        envio: 0,
        recebimento: 0,
        erro: 0,
        callback: 0,
        limiteCredito: 0,
        saldo: 0,
        usuario: 0,
        campanha: 0
      },
      cardsCampanha: [],
      filtroPeriodo: {
        periodo: {
          value: "U3D",
          text: "Últimos 3 dias"
        },
        dataInicial: null,
        dataFinal: null
      },
      usuario: {
        atalhos: []
      },
      exibirDashboard: false,
      exibirCampanhasSemCallback: false,
      rankingElosDuradouros: {},
      carregandoRankingElosDuradouros: false
    };
  },
  mounted() {
    if (this.exibirDashboard) {
      this.inicializarDashboard();
    }

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    if (window.$chatwoot) {
      window.$chatwoot.toggleBubbleVisibility("show");
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "isAdmin"]),
    tipoPlanoConta() {
      switch (this.conta.tipoPlano) {
        case "POS":
          return "Pós-pago";
        case "PRE":
          return "Pré-pago";
        default:
          return "Nenhum";
      }
    },
    temBanners() {
      return this.banners.length > 0;
    }
  },
  methods: {
    abrirSelecaoPeriodos(picker) {
      switch (picker) {
        case "estatisticas_campanha":
          this.$refs.campanha_picker.togglePicker(true);
          break;
        default:
          this.$refs.picker.togglePicker(true);
          break;
      }
    },
    periodoSelecionado({ startDate: dataInicial, endDate: dataFinal }) {
      this.carregandoDadosPeriodo = true;
      const objPeriodo = this.obterPeriodo(dataInicial, dataFinal);
      const route = this.$route.name;
      switch (route) {
        case "dashboard":
          this.$bus.$emit("atualizar:dashboard", objPeriodo);
          break;
        case "mapeamento-perfil":
          this.$bus.$emit("atualizar:mapeamento-perfil", objPeriodo);
          break;
      }

      this.textoDataInicial = objPeriodo.textoDataInicial;
      this.textoDataFinal = objPeriodo.textoDataFinal;
    },
    async buscarBanners() {
      const response = await ApiService.get("/banner-recurso/conta", "", false);
      if (response.data.conta_id) {
        this.conta_id = response.data.conta_id;
      }
      if (!response.data.recursos) return [];
      return response.data.recursos;
    },
    async bannerClick(banner) {
      const RECURSO_BANNER_FIDER_ID = 35;
      if (banner.recurso_banner_id == RECURSO_BANNER_FIDER_ID) {
        const usuario = JSON.parse(localStorage.getItem("usuario"));
        window.open(usuario.usu_fider_url, "_blank");
      } else {
        window.$chatwoot.toggle();
      }
      const now = new Date().toISOString().slice(0, 19).replace("T", " ");
      const uid = this.gerarUID();
      const usuario = JSON.parse(localStorage.getItem("usuario"));
      const payload = {
        contaId: this.conta_id,
        recursoTipoId: 10,
        titulo: "Banner: " + banner.rb_titulo,
        descricao: `Cliente: ${usuario.cliente} | Conta: ${usuario.conta} | Login: ${usuario.login}`,
        infoDispositivo: {
          ip: null,
          sistema: null,
          dispositivo: null,
          navegador: null
        },
        uid,
        origemChamada: "Painel Interno - Banner",
        dataInicio: now,
        dataFim: now
      };
      ApiService.post("/estatistica", payload, {
        baseURL: process.env.VUE_APP_COMUNICANTE_API
      });
    },
    // função utilizada em outros projetos para gerar UID consistente entre aplicações, não alterar
    gerarUID(prefix, moreEntropy) {
      if (typeof prefix === "undefined") {
        prefix = "";
      }
      let retId;
      const _formatSeed = function (seed, reqWidth) {
        seed = parseInt(seed, 10).toString(16); // to hex str
        if (reqWidth < seed.length) {
          return seed.slice(seed.length - reqWidth);
        }
        if (reqWidth > seed.length) {
          return Array(1 + (reqWidth - seed.length)).join("0") + seed;
        }
        return seed;
      };
      const $global = typeof window !== "undefined" ? window : global;
      $global.$locutus = $global.$locutus || {};
      const { $locutus } = $global;
      $locutus.php = $locutus.php || {};
      if (!$locutus.php.uniqidSeed) {
        // init seed with big random int
        $locutus.php.uniqidSeed = Math.floor(Math.random() * 0x75bcd15);
      }
      $locutus.php.uniqidSeed++;
      // start with prefix, add current milliseconds hex string
      retId = prefix;
      retId += _formatSeed(parseInt(new Date().getTime() / 1000, 10), 8);
      // add seed hex string
      retId += _formatSeed($locutus.php.uniqidSeed, 5);
      if (moreEntropy) {
        // for more entropy we add a float lower to 10
        retId += (Math.random() * 10).toFixed(8).toString();
      }
      return retId;
    },
    async inicializarDashboard() {
      ApiService.get("/dashboard/periodos").then((response) => {
        this.sltPeriodos = response.data.data.sltPeriodos;
      });

      const periodo = {
        dataInicial: null,
        dataFinal: null
      };

      this.dadosDashboard(periodo);

      const self = this;

      this.$bus.$on("atualizar:dashboard", function (periodo) {
        self.filtroPeriodo = periodo;
        self.dadosDashboard(periodo);
      });

      ApiService.get("/dashboard/timeline", "", false).then((response) => {
        const dados = response.data.data;

        if (dados) {
          this.timeline.lista = dados.timeline;
        }

        this.exibirTimeline = true;
      });

      ApiService.post("/dashboard/cards", periodo, null, false).then(
        (response) => {
          const dados = response.data.data;

          if (dados) {
            this.cards = dados.cards;
            this.conta = dados.conta;
          }
        }
      );

      this.banners = await this.buscarBanners();
    },
    mergeLineChartData(name_key, wpp_series, sms_series) {
      const series = [];
      const series_wpp = wpp_series;
      const series_sms = sms_series;
      const series_wpp_length = series_wpp.length;
      const series_sms_length = series_sms.length;
      const series_length =
        series_wpp_length > series_sms_length
          ? series_wpp_length
          : series_sms_length;
      for (let i = 0; i < series_length; i++) {
        const serie = {
          x: "",
          y: 0
        };

        if (series_wpp[i]) {
          serie.x = series_wpp[i].x;
          serie.y += +series_wpp[i].y;
        }

        if (series_sms[i]) {
          serie.x = series_sms[i].x;
          serie.y += +series_sms[i].y;
        }

        series.push(serie);
      }

      return {
        name: name_key,
        data: series
      };
    },
    dadosDashboard(periodo) {
      this.exibirGraficos = false;
      const payload = { ...periodo };

      ApiService.post("/dashboard/graficos", payload, null, false).then(
        (response) => {
          const dados = response.data.data;

          if (dados) {
            this.graficoDiario.series_whatsapp =
              dados.graficoDiario.series_whatsapp;
            this.graficoDiario.series_sms = dados.graficoDiario.series_sms;
            this.graficoDiario.total_whatsapp =
              dados.graficoDiario.total_whatsapp;
            this.graficoDiario.total_sms = dados.graficoDiario.total_sms;

            const total_series = [
              this.mergeLineChartData(
                "Erros",
                dados.graficoDiario.series_whatsapp.find(
                  (serie) => serie.name === "Erros"
                ).data,
                dados.graficoDiario.series_sms.find(
                  (serie) => serie.name === "Erros"
                ).data
              ),
              this.mergeLineChartData(
                "Recebimentos",
                dados.graficoDiario.series_whatsapp.find(
                  (serie) => serie.name === "Recebimentos"
                ).data,
                dados.graficoDiario.series_sms.find(
                  (serie) => serie.name === "Recebimentos"
                ).data
              ),
              this.mergeLineChartData(
                "Envios",
                dados.graficoDiario.series_whatsapp.find(
                  (serie) => serie.name === "Envios"
                ).data,
                dados.graficoDiario.series_sms.find(
                  (serie) => serie.name === "Envios"
                ).data
              )
            ];

            this.graficoDiario.total_aglutinado =
              +dados.graficoDiario.total_sms +
              dados.graficoDiario.total_whatsapp;
            this.graficoDiario.total_aglutinado_series = total_series;

            this.exibirGraficos = true;
            this.carregandoDadosPeriodo = false;

            if (this.$refs.graficoLinha) {
              setTimeout(() => {
                this.$refs.graficoLinha.updateFromPicker();
              }, 500);
            }
          }
        }
      );

      this.buscarDadosCardCampanha(periodo);
      this.buscarDadosElosDuradoros();
    },

    buscarDadosCardCampanha(periodo) {
      return ApiService.post(
        "/dashboard/cards-campanha",
        {
          periodo: periodo,
          agrupamento: "campanha",
          exibirCampanhasSemCallback: this.exibirCampanhasSemCallback
        },
        null,
        false
      ).then((response) => {
        const dados = response.data.data;

        if (dados) {
          this.cardsCampanha = dados.sort(
            (a, b) => b.percentual - a.percentual
          );
        }
      });
    },

    buscarDadosElosDuradoros() {
      this.carregandoRankingElosDuradouros = true;
      ApiService.get("/dashboard/elos-duradouros", "", false)
        .then((response) => {
          const dados = response.data.data;

          if (dados) {
            this.rankingElosDuradouros = this.ordenarElosPorPontuacao(dados);
          }
        })
        .finally(() => {
          this.carregandoRankingElosDuradouros = false;
        });
    },

    ativarDashboard() {
      localStorage.setItem("exibirDashboard", true);
      this.exibirDashboard = true;
      this.inicializarDashboard();
    },

    ordenarElosPorPontuacao(data) {
      let sortedData = Object.keys(data)
        .sort((a, b) => {
          const pontuacaoA = data[a].conta_atual.posicao
            ? parseInt(data[a].conta_atual.posicao)
            : Number.MAX_SAFE_INTEGER;
          const pontuacaoB = data[b].conta_atual.posicao
            ? parseInt(data[b].conta_atual.posicao)
            : Number.MAX_SAFE_INTEGER;
          return pontuacaoA - pontuacaoB;
        })
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {});
      return sortedData;
    }
  },

  watch: {
    exibirCampanhasSemCallback: async function () {
      this.carregandoDadosPeriodo = true;
      await this.buscarDadosCardCampanha(this.filtroPeriodo);
      this.carregandoDadosPeriodo = false;
    }
  }
};
</script>
<!-- <script>

</script> -->
<style lang="scss">
.timeline-container {
  @media (min-width: 768px) {
    height: 600px;
  }
}
.estatistica-mensagens-container {
  height: 500px;
}
.da-menu {
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
}
.panel-heading {
  .vac-menu-options {
    right: 30px;
    top: 30px;
  }
  .vac-menu-list {
    padding: 0;
    .vac-menu-item {
      padding: 0;
    }
  }
}

.woot-elements--right {
  background-color: #472282 !important;
}

/* ffc107 */
.v-dashboard-container {
  .carousel-indicators li {
    background-color: #ffc107 !important;
  }
  .p-inputswitch .p-inputswitch-slider {
    border-radius: 15px !important;
  }
  .p-inputswitch {
    width: 2.8rem !important;
    height: 1.4rem !important;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #b6b6b6 !important;
    border-radius: 50% !important;
    width: 1.1rem !important;
    height: 1.1rem !important;
    margin-top: -0.54rem !important;
    left: 0.22rem !important;
  }
  .p-inputswitch .p-inputswitch-slider,
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #ffffff !important;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #472282 !important;
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    -webkit-box-shadow: 0 0 0 0.1rem rgba(206, 212, 218, 0.5) !important;
    box-shadow: 0 0 0 0.1rem rgba(206, 212, 218, 0.5) !important;
  }
}
</style>
