<template>
  <div class="shortcut-container">
    <modal
      name="modal-atalhos"
      class="modal-atalhos"
      scrollable
      width="400px"
      height="auto"
    >
      <div class="modal-body">
        <div class="d-flex align-items-center" style="gap: 0.7rem">
          <h3 class="m-0">Barra de atalhos</h3>
          <Tooltip
            message="Os atalhos são responsáveis por facilitar o seu acesso à funcionalidades do sistema"
          />
        </div>
        <p class="mt-3">
          Selecione <b style="color: #ff7f00">até quatro atalhos</b> que você
          pode utilizar para personalizar a sua barra de atalhos.
        </p>
        <draggable
          class="shortcuts-list"
          tag="ul"
          v-model="usuario.atalhos"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          @change="onDragMove"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <li
              class="shortcut-list-item"
              v-for="(atalho, index) in usuario.atalhos"
              :key="index"
              @click="
                toggleShortcut(
                  atalho,
                  maxShortcutsThreshold && !atalho.upa_ativo
                )
              "
              :class="
                maxShortcutsThreshold && !atalho.upa_ativo ? 'disabled' : ''
              "
            >
              <div class="drag-icon">
                <i class="fa-solid fa-ellipsis-vertical"></i>
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div>
              <div
                class="checkbox-wrapper-2"
                :class="
                  maxShortcutsThreshold && !atalho.upa_ativo ? 'disabled' : ''
                "
                v-b-tooltip.hover
                :title="
                  maxShortcutsThreshold && !atalho.upa_ativo
                    ? 'Você atingiu o limite de atalhos ativos'
                    : ''
                "
              >
                <input
                  type="checkbox"
                  class="ikxBAC"
                  :checked="atalho.upa_ativo"
                  :disabled="maxShortcutsThreshold && !atalho.upa_ativo"
                />
              </div>
              <div class="shortcut-icon">
                <i :class="atalho.pat_icone"></i>
              </div>
              <div class="shortcut-title">
                {{ atalho.pat_titulo }}
              </div>
            </li>
          </transition-group>
        </draggable>
      </div>
    </modal>
    <div
      class="atalho-item"
      v-for="(atalho, index) in activeShortcuts"
      :key="index"
      @click="onClickShortcutRedirect(atalho)"
    >
      <i :class="atalho.pat_icone"></i>
      <span>
        {{ atalho.pat_titulo }}
      </span>
    </div>
    <div class="atalho-item plus-icon plus-item" @click="showModalShortcut">
      <i class="fa fa-plus"></i>
      <span> Adicionar Atalho </span>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";
import Tooltip from "./Tooltip.vue";
import draggable from "vuedraggable";

export default {
  components: { Tooltip, draggable },
  props: {
    usuario: {
      type: Object,
      required: true
    }
  },
  name: "Atalhos",
  data() {
    return {
      drag: false
    };
  },
  methods: {
    onDragMove(evt) {
      this.usuario.atalhos.forEach((atalho, index) => {
        atalho.upa_ordem = index + 1;
      });
      const payload = { atalhos: null };
      payload.atalhos = this.usuario.atalhos;
      ApiService.put(API_LINKS.atalho + "/atualizar-ordem", payload, false);

      const usuario = JSON.parse(localStorage.getItem("usuario"));
      usuario.atalhos = this.usuario.atalhos;
      localStorage.setItem("usuario", JSON.stringify(usuario));
    },
    toggleShortcut(atalho, disabled) {
      if (disabled) return;
      atalho.upa_ativo = !atalho.upa_ativo;
      const paylaod = {
        usuario_painel_atalho_id: atalho.usuario_painel_atalho_id,
        upa_ativo: atalho.upa_ativo
      };
      ApiService.patch(API_LINKS.atalho + "/toggle", paylaod, "", false);

      const usuario = JSON.parse(localStorage.getItem("usuario"));
      usuario.atalhos = this.usuario.atalhos;
      localStorage.setItem("usuario", JSON.stringify(usuario));
    },
    showModalShortcut() {
      this.$modal.show("modal-atalhos");
    },
    async onClickShortcutRedirect(atalho) {
      const payload = {
        usuario_painel_atalho_id: atalho.usuario_painel_atalho_id
      };
      ApiService.patch(API_LINKS.atalho + "/click", payload, "", false);
      this.$router.push(atalho.pat_caminho_url);
    }
  },
  computed: {
    maxShortcutsThreshold() {
      return (
        this.usuario.atalhos.filter((atalho) => atalho.upa_ativo).length >= 4
      );
    },
    activeShortcuts() {
      return this.usuario.atalhos.filter((atalho) => atalho.upa_ativo);
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
::v-deep .vm--modal {
  border-radius: 16px !important;
}
.flip-list-move {
  transition: transform 0.75s;
}
.shortcuts-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .shortcut-list-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 8px 0 0 0;
    cursor: pointer !important;
    .shortcut-icon {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
  }
}
.shortcut-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 21%;
  right: 0;
  gap: 1rem;
  .atalho-item {
    z-index: 10;
    width: 100%;
    background: #ff7f00;
    white-space: nowrap;
    border-radius: 24px 0 0 24px;
    padding: 8px 16px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
    transition: all 0.1s ease-in-out;
    position: relative;
    font-weight: 400;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;

    &:hover {
      transform: translateX(-15px);
      box-shadow: none;
    }
    &.plus-icon {
      transform: translateX(75%);
      transition: all 0.3s ease-in-out;
      span {
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        transform: translateX(0%);
        span {
          opacity: 1;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      background: #ff7f00;
      right: 0;
      top: 0;
      width: 33px;
      z-index: -1;
      transform: translateX(30px);
      height: 100%;
      bottom: 0;
    }
    &.plus-item {
      background: #472282;
      &::before {
        background: #472282;
      }
    }
    i {
      color: #fff;
      font-size: 16px;
    }
  }
}
.checkbox-wrapper-2 {
  height: 20px !important;
}
.checkbox-wrapper-2 {
  &.disabled {
    .ikxBAC {
      cursor: not-allowed !important;
    }
    opacity: 0.5;
  }
  .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
    cursor: pointer;
  }
  .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2.disabled .ikxBAC:hover {
  background-color: #dfe1e4;
  transition-duration: 0s;
}
.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #ff7f00;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #ffa088;
}
</style>
