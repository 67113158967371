<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div
      class="d-flex align-items-center justify-content-center flex-column mb-4"
    >
      <h6 class="text-center m-0">
        {{ titulo }}
      </h6>
      <b-button
        variant="link"
        class="font-weight-bold"
        style="
          color: #b5b5c3;
          font-size: 12px;
          margin-top: -0.5rem;
          text-transform: capitalize;
        "
        @click="abrirDetalhes"
      >
        ver detalhes
      </b-button>
    </div>

    <apexchart
      type="donut"
      :height="400"
      :width="250"
      :options="chartOptions"
      :series="[recebido, enviado - recebido]"
      ref="chart"
    ></apexchart>
  </div>
</template>

<script>
import Tooltip from "./Tooltip.vue";
export default {
  components: {
    // Tooltip
  },
  props: {
    titulo: {
      type: String,
      required: true
    },
    enviado: {
      type: Number,
      required: true
    },
    recebido: {
      type: Number,
      required: true
    },
    percentual: {
      type: Number,
      required: true
    },
    card_id: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatterMethod() {
      return `${this.percentual.toFixed(1)}%`;
      // return this.enviado;
    },
    abrirDetalhes() {
      // emit
      this.$emit("abrirDetalhesCampanhaEstatistica", this.card_id);
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "donut",
          toolbar: {
            show: false
          },
          offsetY: 0,
          dropShadow: {
            enabled: false,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.5
          }
        },
        stroke: {
          enabled: false,
          width: 0,
          colors: ["#ccc"]
        },
        colors: ["#472282", "#ff7f00"],
        labels: ["Respostas", "Sem retorno"],
        dataLabels: {
          enabled: false
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: "16px",
                  offsetY: 16
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Respostas",
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: "#373d3f",

                  formatter: this.formatterMethod
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              }
            }
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-text.apexcharts-datalabel-value {
  font-size: 24px !important;
  transform: translateY(-20px) !important;
  font-weight: 600;
}

::v-deep .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  bottom: 5px !important;
}
</style>
