<template>
  <div
    class="card card-custom gutter-b p-8 m-0 h-80"
    @mouseleave="closeMenu"
    style="height: 95%"
  >
    <v-overlay :value="carregando" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-content-between">
      <div>
        <div class="d-flex align-items-center">
          <h5 class="m-0 mr-3" style="font-size: 1.18rem">
            Ranking Elos Duradouros
          </h5>
          <Tooltip
            message="A empresa receberá um prêmio que corresponder ao ranking que representar a sua melhor colocação"
          />
        </div>
        <h6 style="color: #9a9a9a; font-weight: 400; font-size: 1.1rem">
          Posição Mensal - {{ label_botao_menu }}
        </h6>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <b-button
          variant="success"
          size="sm"
          class="ml-2"
          style="
            background: #472282 !important;
            border-color: #472282 !important;
          "
          @click="toggle"
          ref="BotaoMenu"
        >
          {{ label_botao_menu }}
        </b-button>
        <div class="d-flex justify-content-center" style="position: relative">
          <Menu
            ref="menu_togglable"
            id="overlay_menu"
            :model="timeline_filter_options()"
            style="
              position: absolute;
              transform: translateY(20px);
              z-index: 99;
              right: 0;
            "
            v-if="visible_menu_opcoes"
          />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between px-6 py-4 posicao-atual mt-6">
      <div class="d-flex align-items-center">
        <!-- <div
          class="logo mr-4 p-2 d-flex align-items-center justify-content-center"
          style=""
        >
          LOGO
        </div> -->
        <div style="max-width: 230px">
          <h6 class="m-0">{{ contaAtual.conta_nome }}</h6>
          <span
            v-if="contaAtual.pontuacao"
            style="color: #9a9a9a; font-weight: 500"
            >{{ contaAtual.pontuacao | formatacaoNumero }} pontos</span
          >
          <div
            v-if="!contaAtual.posicao"
            style="color: #9a9a9a; font-weight: 500; font-size: 0.8rem"
          >
            Pontuação insuficiente para o ranking
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div
          class="posicao p-2 d-flex align-items-center justify-content-center"
        >
          <h6
            v-if="contaAtual.posicao"
            class="m-0 mr-2"
            style="font-size: 0.95rem"
          >
            {{ contaAtual.posicao }}º
          </h6>
          <img src="/media/svg/elos-duradouros/ranking-star-solid.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div v-if="carregando">
        <b-skeleton
          animation="wave"
          width="100%"
          height="48px"
          class="mt-3"
        ></b-skeleton>
        <b-skeleton
          animation="wave"
          width="100%"
          height="48px"
          class="mt-3"
        ></b-skeleton>
        <b-skeleton
          animation="wave"
          width="100%"
          height="48px"
          class="mt-3"
        ></b-skeleton>
        <b-skeleton
          animation="wave"
          width="100%"
          height="48px"
          class="mt-3"
        ></b-skeleton>
        <b-skeleton
          animation="wave"
          width="100%"
          height="48px"
          class="mt-3"
        ></b-skeleton>
      </div>
      <div
        v-for="(dado, key) in todasContas"
        :key="key"
        class="posicoes-item mb-2 px-6 py-3"
        :class="{
          primeiro: dado.posicao == 1,
          segundo: dado.posicao == 2,
          terceiro: dado.posicao == 3,
          outros: dado.posicao > 3
        }"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <h6 class="mr-1 m-0" style="font-size: 1.5rem; width: 25px">
              {{ dado.posicao }}º
            </h6>
            <div style="width: 30px">
              <img
                v-show="dado.posicao <= 3"
                src="/media/svg/elos-duradouros/medal-solid.svg"
              />
            </div>
            <div
              class="m-0 ml-2 h6"
              :style="`font-size: 0.95rem; ${
                'conta_id' in dado ? '' : 'filter: blur(3px);'
              } white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`"
            >
              {{ dado.conta_nome }}
            </div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <h6 class="m-0" style="font-size: 0.98rem">
              {{ dado.pontuacao | formatacaoNumero }}
            </h6>
            <h6 class="m-0" style="font-size: 0.98rem">pontos</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api/dist/vue-composition-api.js";
import Tooltip from "./Tooltip.vue";
import Menu from "primevue/menu";

export default {
  name: "RankingElosDuradouros",
  components: {
    Tooltip,
    Menu
  },
  props: {
    carregando: {
      type: Boolean,
      required: true
    },
    dados: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selecionado: null,
      visible_menu_opcoes: false
    };
  },
  watch: {
    dados: {
      handler() {
        this.selecionarCategoria(this.label_botao_menu);
      },
      deep: true
    }
  },
  filters: {
    formatacaoNumero(value) {
      let valor = value;
      if (typeof value !== "number") {
        valor = parseInt(value);
      }
      return valor.toLocaleString("pt-BR");
    }
  },
  computed: {
    contaAtual() {
      if (!this.selecionado?.conta_atual) {
        return { conta_nome: "X", posicao: "X", pontuacao: "0" };
      }
      return this.selecionado.conta_atual;
    },
    todasContas() {
      return this.selecionado?.todas_contas || [];
    },
    label_botao_menu() {
      if (!Object.keys(this.dados).length) {
        return "Atende";
      }
      if (!this.selecionado) {
        return (
          Object.keys(this.dados)[0].charAt(0).toUpperCase() +
          Object.keys(this.dados)[0].slice(1)
        );
      }
      return this.selecionado.label;
    }
  },
  methods: {
    selecionarCategoria(categoria) {
      this.selecionado = this.dados[categoria.toLowerCase()];
      if (!this.selecionado) return;
      this.selecionado.label =
        categoria.charAt(0).toUpperCase() + categoria.slice(1);
      this.closeMenu();
    },
    closeMenu() {
      this.visible_menu_opcoes = false;
    },
    toggle() {
      this.visible_menu_opcoes = !this.visible_menu_opcoes;
    },
    timeline_filter_options() {
      return Object.keys(this.dados).map((key) => {
        return {
          label: key.charAt(0).toUpperCase() + key.slice(1),
          command: () => {
            this.label_botao_menu = key.charAt(0).toUpperCase() + key.slice(1);
            this.selecionarCategoria(key);
          }
        };
      });
    }
  }
};
</script>

<style scoped>
.posicao-atual {
  border-radius: 8px;
  background: #f1f1f1;
  box-shadow: 0px 2px 10px 0px rgba(255, 255, 255, 0.1);
}

.posicao-atual .logo {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  height: 60px;
  width: 60px;
}

.posicao-atual .posicao {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  /* height: 40px;
  width: 60px; */
}

.posicoes-item {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(255, 255, 255, 0.1);
}

.posicoes-item.primeiro {
  background: #ffc758;
  color: white;
}

.posicoes-item.segundo {
  background: #c5e1e5;
  color: white;
}

.posicoes-item.terceiro {
  background: #f08d56;
  color: white;
}

.posicoes-item.outros {
  background: #f1f1f1;
}
</style>
