<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >
        <div class="d-flex flex-column w-100">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h5 class="m-0 mr-3">Estatísticas de Créditos - Mensal</h5>
              <Tooltip
                message="O gráfico de estatísticas mensais representa os créditos do mês atual,  demonstrando o saldo utilizado e limite de créditos."
              />
            </div>
            <div>
              <b-button
                variant="success"
                size="sm"
                class="ml-2"
                style="
                  background: #472282 !important;
                  border-color: #472282 !important;
                "
                @click="toggle"
                ref="BotaoMenu"
              >
                {{ label_botao_menu }}
              </b-button>
              <div
                class="d-flex justify-content-center"
                style="position: relative"
                v-if="visible_menu_opcoes"
              >
                <Menu
                  ref="menu_togglable"
                  id="overlay_menu"
                  :model="messageChannelFilterOptions"
                  style="
                    position: absolute;
                    transform: translateY(4px);
                    z-index: 99;
                    right: 0;
                  "
                />
              </div>
            </div>
          </div>
          <div class="mt-2 mb-4 message-info-container">
            <div class="d-flex align-items-center justify-content-center">
              <div
                class="custom-badge"
                :class="
                  creditoPorcentagem > 0.6
                    ? 'green'
                    : creditoPorcentagem > 0.15
                    ? 'orange'
                    : 'red'
                "
              ></div>
              Saldo Utilizado ({{ dados.limiteCredito - canal.saldo }})
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="custom-badge gray"></div>
              Limite de créditos ({{ dados.limiteCredito }}) - {{ plano }}
            </div>
          </div>
          <b-progress
            v-b-tooltip.hover
            :title="'Saldo restante: ' + canal.saldo"
            :value="dados.limiteCredito - canal.saldo"
            :max="dados.limiteCredito"
            :show-value="false"
            class="w-100 mt-5"
            :variant="
              creditoPorcentagem > 0.6
                ? cor_inicial_barra
                : creditoPorcentagem > 0.15
                ? 'warning'
                : 'danger'
            "
            style="height: 24px; border-radius: 12px"
          ></b-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip.vue";
import Menu from "primevue/menu";

export default {
  props: {
    dados: {
      type: Object,
      required: true
    },
    plano: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      opcoesGrafico: {},
      visible_menu_opcoes: false,
      label_botao_menu: "Todos",
      cor_inicial_barra: "success",
      canal: {
        saldo:
          this.dados.limiteCredito -
          this.dados.totalWhatsapp -
          this.dados.totalSMS -
          this.dados.totalEmail
      }
    };
  },
  methods: {
    toggle() {
      this.visible_menu_opcoes = !this.visible_menu_opcoes;
    },
    clickAll() {
      this.label_botao_menu = "Todos";
      this.$refs.BotaoMenu.style.background = "#472282";
      this.$refs.BotaoMenu.style.borderColor = "#472282";
      this.updateAll();
      this.closeMenu();
    },
    clickWpp() {
      this.label_botao_menu = "WhatsApp";
      this.$refs.BotaoMenu.style.background = "#00a884";
      this.$refs.BotaoMenu.style.borderColor = "#00a884";
      this.updateWpp();
      this.closeMenu();
    },
    clickSMS() {
      this.label_botao_menu = "SMS";
      this.$refs.BotaoMenu.style.background = "#ff7f00";
      this.$refs.BotaoMenu.style.borderColor = "#ff7f00";
      this.updateSMS();
      this.closeMenu();
    },
    clickEmail() {
      this.label_botao_menu = "E-mail";
      this.$refs.BotaoMenu.style.background = "#3699ff";
      this.$refs.BotaoMenu.style.borderColor = "#3699ff";
      this.updateEmail();
      this.closeMenu();
    },
    closeMenu() {
      this.visible_menu_opcoes = false;
    },
    updateAll() {
      this.canal.saldo =
        this.dados.limiteCredito -
        this.dados.totalWhatsapp -
        this.dados.totalSMS -
        this.dados.totalEmail;
    },
    updateWpp() {
      let wppRecebimento = this.dados.recebimentosPorCanal.find(
        (i) => i.canal == "Whatsapp"
      ).quantidade;
      let wppEnvios = this.dados.enviosPorCanal.find(
        (i) => i.canal == "Whatsapp"
      ).quantidade;
      this.canal.saldo =
        parseInt(this.dados.limiteCredito) -
        parseInt(wppEnvios) -
        parseInt(wppRecebimento);
    },
    updateSMS() {
      let recebimento = this.dados.recebimentosPorCanal.find(
        (i) => i.canal == "SMS"
      ).quantidade;
      let envios = this.dados.enviosPorCanal.find(
        (i) => i.canal == "SMS"
      ).quantidade;
      this.canal.saldo =
        parseInt(this.dados.limiteCredito) -
        parseInt(envios) -
        parseInt(recebimento);
    },
    updateEmail() {
      let recebimento = this.dados.recebimentosPorCanal.find(
        (i) => i.canal == "E-Mail"
      ).quantidade;
      let envios = this.dados.enviosPorCanal.find(
        (i) => i.canal == "E-Mail"
      ).quantidade;
      this.canal.saldo =
        parseInt(this.dados.limiteCredito) -
        parseInt(envios) -
        parseInt(recebimento);
    }
  },
  computed: {
    creditoPorcentagem() {
      return this.dados.saldo / this.dados.limiteCredito;
    },
    messageChannelFilterOptions() {
      let options = [
        {
          label: "Todos",
          command: this.clickAll
        },
        {
          label: "WhatsApp",
          command: this.clickWpp
        },
        {
          label: "SMS",
          command: this.clickSMS
        },
        {
          label: "E-mail",
          command: this.clickEmail
        }
      ];
      if (!this.totalSMS && this.totalSMS == 0) {
        options = options.filter((i) => i.label != "SMS");
      }
      if (!this.totalWhatsapp && this.totalWhatsapp == 0) {
        options = options.filter((i) => i.label != "WhatsApp");
      }
      if (!this.totalEmail && this.totalEmail == 0) {
        options = options.filter((i) => i.label != "E-mail");
      }
      if (options.length <= 2) {
        options = options.filter((i) => i.label != "Todos");
      }
      return options;
    }
  },
  watch: {
    dados() {
      this.updateAll();
    }
  },
  components: { Tooltip, Menu }
};
</script>

<style lang="scss" scoped>
::v-deep .progress-bar.bg-success {
  background-color: #472282 !important;
}
::v-deep .progress-bar.bg-warning {
  background-color: #ff7f00 !important;
}
.custom-badge {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 8px;
  &.orange {
    background: #ffa800;
  }
  &.red {
    background: #f54e60;
  }
  &.green {
    background: #472282;
  }
  &.gray {
    background: #d8d8d8;
  }
}
.card {
  transition: 0.3s ease;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.message-info-container {
  display: flex;
  gap: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.2rem;
    div {
      justify-content: flex-start !important;
    }
  }
}
</style>
